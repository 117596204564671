<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <div class="copyright">
        &copy; {{ year }}, Designed by
        <a href="https://www.codaris.ga" target="_blank" rel="noopener"
          >Codaris</a
        >.
      </div>
      <div>
        <a href="/privacy">Mentions Légales & Données personnelles</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
}
</script>
<style></style>
