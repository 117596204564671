<template>
  <div>
    <div class="page-header clear-filter" filter-color="dark">
      <parallax
        class="page-header-image"
        style="background-image:url('img/dev/diplome.jpg')"
      >
      </parallax>
      <div class="container ">
        <div class="row">
          <div class="col-md-6 col-12 mt-5">
            <div class="text-center">
              <img
                class="n-logo"
                src="logo.png"
                alt="academia"
                style="width: 250px"
              />
              <h3>Pour tous les élèves de la 3ème en Terminale</h3>
              <h5 class="description">
                Découvrez votre banque de données académique et des professeurs
                disponibles pour votre suivi scolaire en ligne ou en présenciel
              </h5>
              <div class="text-center mt-2">
              <h4>
                Abonnez-vous et accédez à tous les documents
              </h4> 
              <b-button variant="white" size="lg" href='#premium' pill wide class="text-success bg-white"><strong>Passer au compte PREMIUM</strong> </b-button>
            </div>
            <div class="mt-2">
              <a
                href="https://play.google.com/store/apps/details?id=ga.academiagabon"
                target="blank"
                class="m-2"
              >
                <img
                  src="img/dev/playstore.png"
                  width="200"
                  alt="playstore_logo"
                  class="img-fluid"
                />
              </a>
              <a
              class="m-2"
                href="https://apps.apple.com/app/id1606955207"
                target="blank"
              >
                <img
                  src="img/dev/app-store.png"
                  width="200"
                  alt="appstore_logo"
                  class="img-fluid"
                />
              </a>
            </div>
              
            </div>
            
          </div>
          <div class="col-md-6 d-fex justify-content-center mt-5 ">
            <img src="img/dev/phone.png" alt="" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Academia, C'est quoi?</h2>
            <h5 class="description">
              Academia est une application mobile scolaire qui met à la
              disposition des élèves du secondaire une banque de documents
              (Devoirs, TD, Examen…) et leurs corrigés. De plus, l’application
              intègrera un forum dans lequel les élèves auront l’assistance en
              ligne d’enseignants qualifiés disponibles 7j/7.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('img/dev/paper.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary">
                  "Les épreuves et corrigés disponibles me permettent de mieux
                  travailler et préparer mes examens"
                  <br />
                  <br />
                  <small>-YOAN, Terminale A1</small>
                </p>
              </div>

              <!-- Second image on the left side of the article -->
              <div
                class="image-container"
                style="background-image: url('img/dev/desc.png')"
              ></div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right"
                style="background-image: url('img/dev/student_tab.jpeg')"
              ></div>
              <h2>
                Nos objectifs
              </h2>
              <h5>Construire la plus grande banque de documents scolaires</h5>
              <p>
                Notre objectif est de collecter l'ensemble des documents
                scolaires (évaluations, examens et travaux dirigés) existants au
                Gabon depuis le début du 21ème siècle. Conscients de l'immense
                tâche qui nous incombe, nous avons constitué une équipe
                d'enseignants dans diverses matières, chargée de retrouver et
                corriger toutes ces ressources.
              </p>
              <h5>Rendre les documents accessibles</h5>

              <p>
                Toutes nos ressources numériques seront accessibles via notre
                application mobile. En fonction de votre profil (apprenant ou
                enseignant), vous aurez accès à tous les documents de votre
                classe si vous êtes apprenant et à toutes les ressources
                numériques concernant votre matière si vous êtes un enseignant.
                Un compte premium vous sera attribué après abonnement pour
                bénéficier de plus de contenu sur l'application.
              </p>
              <h5>Forum académique</h5>
              <p>
                Uniquement disponible pour les comptes premium, le forum
                académique est principalement destiné aux apprenants. Il
                permettra aux élèves rencontrant des difficultés d'apprentissage
                de poser, en toute liberté et quiétude, leurs questions aux
                enseignants et personnels minutieusement désignés par la
                structure Academia Gabon.
              </p>
              <h5>Cours particuliers</h5>
              <p>
                Les enseignants gagneront en visibilité via l'application. Ils
                seront vus par toute la communauté générée via notre plateforme.
                Les apprenants désireux de suivre des cours particuliers
                pourront les joindre directement et discuter des modalités
                d'inscriptions au cours. Cette option est uniquement disponible
                pour les comptes premium.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

<div class="section " id="premium">
      <div class="container text-justify">
        <h2 class="title text-center">Passer au compte PREMIUM</h2>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-4">
              <div
              
              >
              <img src="img/dev/subscription.gif" alt=""></div>
            </div>
            <div class="col-md-8 my-auto">
              
              
              <p>
                Pour vous abonner et passer au compte PREMIUM, vous devez au préalable être inscrit•e sur l'application mobile "Academia Gabon".
                <br>
                (Vous pouvez la télécharger sur <a target="blank" href="https://play.google.com/store/apps/details?id=ga.academiagabon">Playstore</a> ou <a href="https://apps.apple.com/app/id1606955207"
                target="blank">Appstore</a>).
              </p>
              <p>Accédez ensuite à la plateforme <a href="https://academiagabon.ga">academiagabon.ga</a> 
              <br>
              Cliquez sur le menu "<a href="https://academiagabon.ga/login">Se connecter</a>".</p>
              <p>Une fois connecté•e, veuillez suivre les étapes suivantes : </p>
              <p><strong>Etape 1 : </strong>Sélectionnez un forfait en cliquant sur ( ACA30 ou ACA90 );</p>
              <p><strong>Etape 2 : </strong>Choisissez un Opérateur Mobile ( Airtel Money ou Moov Money );</p>
              <p><strong>Etape 3 : </strong>Entrez le numéro mobile money avec lequel vous voulez payer ( 077 / 066 / ... );</p>
              <p><strong>Etape 4 : </strong>Si vous avez un code promo : Cochez la case - "Code promoteur", saisissez le code puis cliquez sur "Payer";</p>
              <p><strong>Etape 5 : </strong>Sinon, Cliquez sur "Payer".</p>
              <p><strong>Ps : </strong><em>Après avoir cliqué sur "Payer" , le téléphone qui a le numéro mobile money aura une fenêtre qui s'affichera pour confirmer son payement.</em></p>
              <br>
              <div class="text-center">
                <b-button variant="primary" size="lg" href="https://academiagabon.ga/login" pill wide class=""><strong>Se connecter</strong> </b-button>
              </div>

            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Notre Equipe</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="image/ollomo.png"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Séverin OLLOMO ELLA</h4>
                <p class="category text-primary">CEO ACADEMIA GABON</p>
                <p class="description">
                  Enseignant de Mathématiques
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="image/abba.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Abakar ABBA KOMI</h4>
                <p class="category text-primary">
                  COO ACADEMIA GABON
                </p>
                <p class="description">
                  Enseignant de Mathematiques
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="image/darryn.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Darryn TSOUNGHAT</h4>
                <p class="category text-primary">CEO CODARIS</p>
                <p class="description">
                  Ingénieur Réseaux et Télécommunications &<br />
                  Développeur d'application mobile
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Contactez nous</h2>
        <p class="description">
          Vos questions et remarques sont très importantes pour nous.
        </p>
        <div class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <fg-input
              class="input-lg"
              placeholder="Votre Nom..."
              v-model="form.firstName"
              addon-left-icon="now-ui-icons users_circle-08"
            >
            </fg-input>
            <fg-input
              class="input-lg"
              placeholder="Votre Email..."
              v-model="form.email"
              addon-left-icon="now-ui-icons ui-1_email-85"
            >
            </fg-input>
            <div class="textarea-container">
              <textarea
                class="form-control"
                name="name"
                rows="4"
                cols="80"
                v-model="form.message"
                placeholder="Ecrivez votre message..."
              ></textarea>
            </div>
            <alert type="success" v-if="message" class="col-12">{{
              message
            }}</alert>

            <alert type="danger" v-if="error" class="col-12">{{ error }}</alert>
            <div class="send-button">
              <n-button type="primary" round block size="lg" @click="sendMail"
                >Envoyez votre message
                <div class="fas fa-spinner fa-pulse" v-if="isloading"></div
              ></n-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Alert, Button, FormGroupInput } from '@/components'
import AdminService from '@/services/AdminService'

export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    Alert,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      isloading: false,
      error: false,
      message: false,
      form: {
        firstName: '',
        email: '',
        message: '',
      },
    }
  },
  methods: {
    async sendMail() {
      try {
        this.isloading = true
        this.error = false
        this.message = false
        if (this.form.email && this.form.message) {
          const response = (
            await AdminService.sendMail({
              from: this.form.email,
              to: 'support@academiagabon.ga',
              subject: 'Message Contactez-nous',
              message: this.form.firstName + '\n\n' + this.form.message,
            })
          ).data

          this.isloading = false
          this.message = response.message
        } else {
          this.isloading = false
          this.error = 'Veuillez remplir tous les champs'
        }
      } catch (error) {
        this.isloading = false
        this.error = error.response ? error.response.data.error : error
      }
    },
  },
}
</script>
<style></style>
